import { createContext, useContext } from 'react';
import useUser from './use-user';
const UserContext = createContext();
const UserProvider = ({ children }) => {
    const { user } = useUser();

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
    return useContext(UserContext);
};

export { UserProvider, useUserContext };
