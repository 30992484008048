import Link from "next/link";
import getConfig from "next/config";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useUserContext } from "../userContext";

const MccHeader = () => {
  const router = useRouter();
  const nextConfig = getConfig();
  const navLinkBaseClass = "nav-link px-3";
  const ariaCurrent = { "aria-current": "page" };
  const dashboardAria =
    router === null || router.pathname === null || router.pathname === "/"
      ? ariaCurrent
      : {};
  const productsAria =
    router.pathname.substring(0, 9) === "/products" ? ariaCurrent : {};
  const vocabAria = router.pathname === "/vocabularies" ? ariaCurrent : {};
  const helpAria = router.pathname === "/help" ? ariaCurrent : {};
  const adminAria = router.pathname === "/administration" ? ariaCurrent : {};

  //determine if user should see admin link in header
  const user = useUserContext();
  return (
    <nav className='navbar navbar-expand-lg navbar-dark bg-primary'>
      <div className='container-fluid'>
        <span className='navbar-brand mb-0 h1'>MCC Manager</span>
        {nextConfig.publicRuntimeConfig.deploymentEnv === "prod" ? (
          <span className={classes.betaTesting}>Beta</span>
        ) : (
          <span className='currentEnvironment'>
            {nextConfig.publicRuntimeConfig.deploymentEnv}
          </span>
        )}
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNavAltMarkup'
          aria-controls='navbarNavAltMarkup'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
          <div className='navbar-nav'>
            <Link href='/' passHref>
              <a
                className={classNames(navLinkBaseClass, {
                  active:
                    router === null ||
                    router.pathname === null ||
                    router.pathname === "/",
                })}
                {...dashboardAria}
              >
                Dashboard
              </a>
            </Link>
            <Link href='/products' passHref>
              <a
                className={classNames(navLinkBaseClass, {
                  active: router.pathname.substring(0, 9) === "/products",
                })}
                {...productsAria}
              >
                Products
              </a>
            </Link>
            <Link href='/vocabularies' passHref>
              <a
                className={classNames(navLinkBaseClass, {
                  active: router.pathname === "/vocabularies",
                })}
                {...vocabAria}
              >
                Vocabularies
              </a>
            </Link>
            <Link href='/help' passHref>
              <a
                className={classNames(navLinkBaseClass, {
                  active: router.pathname === "/help",
                })}
                {...helpAria}
              >
                Help
              </a>
            </Link>
            {user && user.isMccIcpsrPermitted && (
              <Link href='/administration' passHref>
                <a
                  className={classNames(navLinkBaseClass, {
                    active: router.pathname === "/administration",
                  })}
                  {...adminAria}
                >
                  Admin
                </a>
              </Link>
            )}
            <Link href='/api/auth/logout' passHref>
              <a className='nav-link px-3'>Sign Out</a>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MccHeader;
